import * as constants from "../../constants";
import { routes } from "../../constants/routes";
import { AppState } from "./types";

const currentPage = window.location.pathname
  ? window.location.pathname.split("/")[1].split("?")[0]
  : "";
let currentStep = 4;
routes.forEach((route) => {
  if (route.key === currentPage) {
    currentStep = route.step;
  }
});

const getInitialState = (removeLs?) => {
  let insurantPortalData;
  let insurantPortalUrl;
  let keyDate;
  let insurantFullName;

  if (!removeLs) {
    try {
      insurantPortalData = JSON.parse(
        (window as any).localStorage.getItem("insurantPortalDataWI")
      );
      insurantFullName =
        insurantPortalData?.firstname + " " + insurantPortalData?.name;
      insurantPortalUrl = JSON.parse(
        (window as any).localStorage.getItem("insurantPortalUrlWI")
      );
      keyDate = JSON.parse(
        (window as any).localStorage.getItem("insurantPortalKeyDate")
      );
    } catch (error) {
      insurantPortalData = null;
      insurantPortalUrl = null;
      console.warn("There was a problem with LS", error);
    }
  }

  return {
    monthlyOrYearly: "monthlyIncome",
    sidebarOpened: false,
    avatarSrc:
      "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png",
    loading: false,
    i18nLoading: false,
    simulateLoading: false,
    indexToRemove: null,
    errors: [],
    calculations: null,
    calculationsObj: null,
    calculationSteps: null,
    calculateCounterTrigger: 0,
    calculateCounter: 0,
    completeResponse: null,
    lastCompleteResponse: null,
    currentStep: currentStep,
    lastCalculatePayload: null,
    globalSettings: null,
    isBridingPossible: false,
    cardsConfiguration: "",
    suggestMaxBridgingPensionValues: false,
    defaultRetirmentAgeFemale: 64,
    defaultRetirmentAgeMale: 65,
    maxAHVPensionPerMonth: 0,
    suggestAHVPension: false,
    stepsOpened: {
      1: false,
      2: false,
      3: false,
      4: false,
    },
    insurancePlans: [],
    globalSettingsFetched: false,
    insurantPortalKeyDate: keyDate,
    examplePicturesSet: {
      activityRatePictureUrl: null,
      dateOfIssuePictureUrl: null,
      insurancePlanPictureUrl: null,
      payrollClassPictureUrl: null,
      payrollPictureUrl: null,
      savingsBalancePictureUrl: null,
    },
    insurantPortalData: insurantPortalData,
    insurantFullName: insurantFullName,
    insurantPortalUrl: insurantPortalUrl,
    additionalSavingsPlans: [],
    hasMultiLanguageSupport: false,
    languageItemsList: null,
  };
};

const app = (state: AppState = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.SET_MONTHLY_OR_YEARLY: {
      return {
        ...state,
        monthlyOrYearly: action.payload,
      };
    }

    case constants.FORCE_RENDER_AFTER_INSURANT_LOAD: {
      return {
        ...state,
        forceRender: action.payload,
      };
    }

    case constants.FETCHED_INSURANT_DATA: {
      const initialState = { ...getInitialState(true) };
      const insurantPortalData = action.payload.data;
      const insurantPortalUrl = action.payload.url;
      const cardsConfiguration = action.payload.event;
      const insurantPortalKeyDate = action.payload.keyDate;
      const insurantFullName =
        insurantPortalData?.firstname + " " + insurantPortalData?.name;

      localStorage.setItem(
        "insurantPortalDataWI",
        JSON.stringify(insurantPortalData)
      );
      localStorage.setItem(
        "insurantPortalUrlWI",
        JSON.stringify(insurantPortalUrl)
      );
      localStorage.setItem(
        "insurantPortalKeyDate",
        JSON.stringify(insurantPortalKeyDate)
      );
      return {
        ...initialState,
        insurantFullName,
        insurantPortalData,
        insurantPortalUrl,
        cardsConfiguration,
        insurantPortalKeyDate,
      };
    }

    case constants.REMOVE_INSURANT_PORTAL_DATA: {
      localStorage.removeItem("insurantPortalDataWI");
      localStorage.removeItem("insurantPortalUrlWI");

      return {
        ...state,
        insurantPortalData: null,
        insurantPortalUrl: null,
      };
    }

    case constants.CLEAR_DATA: {
      const initialState = { ...getInitialState() };
      return {
        ...initialState,
      };
    }
    case constants.LOADING: {
      return { ...state, loading: action.payload };
    }
    case constants.I18N_LOADING: {
      return { ...state, i18nLoading: action.payload };
    }
    case constants.SIMULATE_LOADING: {
      return { ...state, simulateLoading: action.payload };
    }
    case constants.INDEX_TO_REMOVE: {
      return { ...state, indexToRemove: action.payload };
    }
    case constants.SET_STEPS_OPENED: {
      return {
        ...state,
        stepsOpened: {
          ...state.stepsOpened,
          ...action.payload,
        },
      };
    }
    case constants.USERDATA_DATENQUELLE: {
      return {
        ...state,
        currentStep: 4,
      };
    }
    case constants.USERDATA_VPKONTO: {
      return {
        ...state,
        currentStep: 11,
      };
    }
    case constants.USERDATA_VPKONTO_AMOUNT: {
      return {
        ...state,
        currentStep: 11,
      };
    }

    case "@@router/LOCATION_CHANGE": {
      return {
        ...state,
        calculateCounterTrigger:
          action.payload.location.pathname !== "/chart"
            ? 0
            : state.calculateCounterTrigger,
      };
    }

    case constants.FORCE_RECALCULATE: {
      return {
        ...state,
        calculateCounterTrigger: state.calculateCounterTrigger + 1,
      };
    }

    case constants.USERDATA_ADDITIONAL_SAVING_PLAN_SELECTED: {
      return {
        ...state,
      };
    }
    case constants.USERDATA_ADDITIONAL_SAVING_PLANS: {
      return {
        ...state,
        additionalSavingsPlans: action.payload,
      };
    }

    case constants.FETCHED_CALCULATIONS: {
      const calculationsObj = {
        teilPensions: [],
        capitalInformation: action.payload.data.capitalInformation,
      };
      let calculationSteps = [];
      action.payload.data.incomePeriodSet.forEach((obj) => {
        if (obj["label"] === "Teilpensionierung") {
          calculationSteps.unshift(obj);
          calculationsObj.teilPensions.unshift(obj);
        } else {
          calculationsObj[obj["label"]] = obj;
        }
      });

      if (calculationsObj["Pensionierung"]) {
        calculationSteps.unshift(calculationsObj["Pensionierung"]);
      } else {
        calculationsObj["AHV-Pensionierung"] &&
          calculationSteps.unshift(calculationsObj["AHV-Pensionierung"]);
      }
      return {
        ...state,
        lastCompleteResponse: state.completeResponse,
        completeResponse: action.payload.data,
        calculations: action.payload.data.incomePeriodSet,
        calculationsObj,
        lastCalculatePayload: action.payload.posted,
        calculationSteps,
        simulateLoading: false,
      };
    }

    case constants.SLIDER_VALUE_PURCHASE: {
      return {
        ...state,
      };
    }

    case constants.SET_LANGUAGES_LIST: {
      return { ...state, languageItemsList: action.payload };
    }
  }

  return state;
};

export default app;
